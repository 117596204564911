import React from "react";
import WritingTemplate from "../../components/writing/template";
import Card from "../../components/writing/template/card";

import story from "../../images/writing/dearselfheader.png";

export default function () {
  return (
    <WritingTemplate
      title="Dear Self"
      subtitle="An open letter to myself."
      imageLink={story}
    >
      <Card>
        <p>
          Your scars will heal and on days when you poke it, it’ll cause no
          pain. You’ll find solace in your past, the memories that sting and the
          bumpy ride because it’s part of the process of being your better self.
        </p>
        <p>
          You’ll find strength to look into the eyes of those that hurt you,
          take away their power to because now, you call the shots. You’ll find
          kindness and warmth in the hands of strangers and friendship in the
          most unexpected places.
        </p>
        <p>
          You’ll find your own person, you’ll find love and companionship and oh
          it’ll change you. Feel, love and experience undeniable miracles. Yield
          to things greater than your ambition; to friendships, to
          companionship, to shots. Free yourself from the weights that you have
          tied to your ankles and the unending list of responsibilities that
          have weighed you down. You deserve to fill your heart with wonder.
          Experience the beauty that has been created for you to enjoy.
        </p>
        <p>
          I hope you experience the happiness that exists on your own terms. I
          hope you have the courage to accept that you’re deserving of
          everything you desire, that you’re capable of curating the kind of
          life that sparks something within you. I hope you find genuine love.
          The kind that makes you a softer person because nothing is more
          beautiful than loving someone who builds you a home in their heart.
          Let your guards down and let it in.
        </p>
      </Card>
      <Card>
        <p>
          I hope you find the kind of moments that take your breath away. I hope
          you surround yourself with friends that encourage your passions and
          spontaneity. I hope you connect with the small things.I hope you meet
          Bryson.I hope you find all the things you yearn for.
        </p>
        <p>
          Above all, I hope you find yourself out there. I hope you learn how to
          be kind to yourself, how to embrace the journey you’re on. I hope you
          fall in love with the process of life, the messiness and confusion of
          it all.
        </p>
        <p>I hope your life inspires you.</p>
      </Card>
    </WritingTemplate>
  );
}
